const castleParams = {
  name: 'plotParams',
  buttonParams: [
    {
      label: 'Rarity',
      subparams: [
        {
          label: 'Silver',
          currentState: false,
          initState: false,
          value: 'Silver',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'castleParams',
          id: 23,
        },
        {
          label: 'Gold',
          currentState: false,
          initState: false,
          value: 'Gold',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'castleParams',
          id: 24,
        },
        {
          label: 'Platinum',
          currentState: false,
          initState: false,
          value: 'Platinum',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'castleParams',
          id: 25,
        },
      ],
    },
  ],
};

export default castleParams;
