import filterTypesParams, { filterTypesParamsWithTickets } from './filterTypesParams';
import avatarParams from './avatarParams';
import carParams from './carParams';
import plotParams from './plotParams';
import statusParams from './statusParams';
import boxesTypesParams from './boxesTypesParams';
import castleParams from './castleParams';

const filterParams = {
  filterTypesParams,
  price: {
    label: 'Price',
    type: 'maxMin',
    currentState: [0, 999],
    initState: [0, 999],
    maxState: 999,
    minState: 0,
    value: 'Price',
    queryName: 'price',
    get queryValue() {
      return `${this.queryName}_min=${this.currentState[0] || 0}&${this.queryName}_max=${this.currentState[1] || 0}`;
    },
    get htmlText() {
      return `${this.label}: Min ${this.currentState[0] || 0} BNB, Max ${this.currentState[1] || 0} BNB`;
    },
    location: 'price',
    id: 5,
  },
  filterTypesSubparams: {
    avatarParams,
    carParams,
    plotParams,
    statusParams,
    boxesTypesParams,
    castleParams,
  },
};

export const filterParamsWithTickets = {
  filterTypesParams: filterTypesParamsWithTickets,
  price: {
    label: 'Price',
    type: 'maxMin',
    currentState: [0, 999],
    initState: [0, 999],
    maxState: 999,
    minState: 0,
    value: 'Price',
    queryName: 'price',
    get queryValue() {
      return `${this.queryName}_min=${this.currentState[0] || 0}&${this.queryName}_max=${this.currentState[1] || 0}`;
    },
    get htmlText() {
      return `${this.label}: Min ${this.currentState[0] || 0} BNB, Max ${this.currentState[1] || 0} BNB`;
    },
    location: 'price',
    id: 5,
  },
  filterTypesSubparams: {
    avatarParams,
    carParams,
    plotParams,
    statusParams,
    boxesTypesParams,
    castleParams,
  },
};

export default filterParams;
