<template>
  <div class="filtering-params"></div>
</template>

<script>
export default {

};
</script>

<style>

</style>
