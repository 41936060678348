const boxesTypesParams = {
  name: 'boxesTypesParams',
  buttonParams: [
    {
      label: 'Type',
      subparams: [
        {
          label: 'Roadster',
          currentState: false,
          initState: false,
          value: 'GENERAL',
          queryName: 'type',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'boxesTypesParams',
          id: 28,
        },
        {
          label: 'Muscle Car',
          currentState: false,
          initState: false,
          value: 'CARS',
          queryName: 'type',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'boxesTypesParams',
          id: 29,
        },
      ],
    },
  ],
};

export default boxesTypesParams;
