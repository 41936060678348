interface AnyObject {
  [key: string]: any
}

export default function shallowClone(obj: AnyObject): AnyObject {
  return Object.create(
    Object.getPrototypeOf(obj),
    Object.getOwnPropertyDescriptors(obj),
  );
}
