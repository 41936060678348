const carParams = {
  name: 'carParams',
  buttonParams: [
    {
      label: 'Rarity',
      subparams: [
        {
          label: 'Common',
          currentState: false,
          initState: false,
          value: 'Common',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'carParams',
          id: 14,
        },
        {
          label: 'Uncommon',
          currentState: false,
          initState: false,
          value: 'Uncommon',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'carParams',
          id: 15,
        },
        {
          label: 'Rare',
          currentState: false,
          initState: false,
          value: 'Rare',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'carParams',
          id: 16,
        },
        {
          label: 'Epic',
          currentState: false,
          initState: false,
          value: 'Epic',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'carParams',
          id: 17,
        },
        {
          label: 'Legendary',
          currentState: false,
          initState: false,
          value: 'Legendary',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'carParams',
          id: 18,
        },
        {
          label: 'Mythic',
          currentState: false,
          initState: false,
          value: 'Mythic',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'carParams',
          id: 19,
        },
      ],
    },
  ],
  sliderParams: [
    {
      label: 'Speed',
      type: 'maxMin',
      currentState: [0, 320],
      initState: [0, 320],
      maxState: 320,
      minState: 0,
      value: 'Speed',
      queryName: 'speed',
      get queryValue() {
        return `${this.queryName}_min=${this.currentState[0]}&${this.queryName}_max=${this.currentState[1]}`;
      },
      get htmlText() {
        return `${this.label}: Min ${this.currentState[0]}, Max ${this.currentState[1]}`;
      },
      location: 'carParams',
      id: 20,
    },
    {
      label: 'Horsepower',
      type: 'maxMin',
      currentState: [0, 500],
      initState: [0, 500],
      maxState: 500,
      minState: 0,
      value: 'Horsepower',
      queryName: 'horsepower',
      get queryValue() {
        return `${this.queryName}_min=${this.currentState[0]}&${this.queryName}_max=${this.currentState[1]}`;
      },
      get htmlText() {
        return `${this.label}: Min ${this.currentState[0]}, Max ${this.currentState[1]}`;
      },
      location: 'carParams',
      id: 21,
    },
    {
      label: 'Handling',
      type: 'maxMin',
      currentState: [0, 100],
      initState: [0, 100],
      maxState: 100,
      minState: 0,
      value: 'Handling',
      queryName: 'handling',
      get queryValue() {
        return `${this.queryName}_min=${this.currentState[0]}&${this.queryName}_max=${this.currentState[1]}`;
      },
      get htmlText() {
        return `${this.label}: Min ${this.currentState[0]}, Max ${this.currentState[1]}`;
      },
      location: 'carParams',
      id: 22,
    },
  ],
};

export default carParams;
