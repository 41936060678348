const filterTypesParams = [
  {
    label: 'All',
    currentState: false,
    initState: false,
    componentsToRender: [
      'PlotFilteringParams',
      'AvatarFilteringParams',
      'CarFilteringParams',
    ],
    value: 'All',
    queryName: 'type',
    get queryValue() {
      return '';
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 1,
  },
  {
    label: 'Plot',
    currentState: false,
    initState: false,
    componentsToRender: [
      'PlotFilteringParams',
    ],
    value: 'LAND',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 2,
  },
  {
    label: 'Avatar',
    currentState: false,
    initState: false,
    componentsToRender: [
      'AvatarFilteringParams',
    ],
    value: 'AVATAR',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 3,
  },
  {
    label: 'Car',
    currentState: false,
    initState: false,
    componentsToRender: [
      'CarFilteringParams',
    ],
    value: 'CAR',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 4,
  },
  {
    label: 'Castle',
    currentState: false,
    initState: false,
    componentsToRender: [
      'CastleFilteringParams',
    ],
    value: 'CASTLE',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 5,
  },
  {
    label: 'Creature',
    currentState: false,
    initState: false,
    componentsToRender: [
      'CreatureFilteringParams',
    ],
    value: 'CREATURE',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 6,
  }
];

export const filterTypesParamsWithTickets = [
  {
    label: 'All',
    currentState: false,
    initState: false,
    componentsToRender: [
      'PlotFilteringParams',
      'AvatarFilteringParams',
      'CarFilteringParams',
    ],
    value: 'All',
    queryName: 'type',
    get queryValue() {
      return '';
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 1,
  },
  {
    label: 'Plot',
    currentState: false,
    initState: false,
    componentsToRender: [
      'PlotFilteringParams',
    ],
    value: 'LAND',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 2,
  },
  {
    label: 'Avatar',
    currentState: false,
    initState: false,
    componentsToRender: [
      'AvatarFilteringParams',
    ],
    value: 'AVATAR',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 3,
  },
  {
    label: 'Car',
    currentState: false,
    initState: false,
    componentsToRender: [
      'CarFilteringParams',
    ],
    value: 'CAR',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 4,
  },
  {
    label: 'Castle',
    currentState: false,
    initState: false,
    componentsToRender: [
      'CastleFilteringParams',
    ],
    value: 'CASTLE',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 5,
  },
  {
    label: 'Creature',
    currentState: false,
    initState: false,
    componentsToRender: [
      'CreatureFilteringParams',
    ],
    value: 'CREATURE',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 6,
  },
  {
    label: 'Ticket',
    currentState: false,
    initState: false,
    componentsToRender: [
    ],
    value: 'TICKET',
    queryName: 'type',
    get queryValue() {
      return `${this.queryName}=${this.value}`;
    },
    get htmlText() {
      return `${this.label}`;
    },
    location: 'filterTypesParams',
    id: 7,
  }
];

export default filterTypesParams;
