const plotParams = {
  name: 'plotParams',
  buttonParams: [
    {
      label: 'Rarity',
      subparams: [
        {
          label: 'Silver',
          currentState: false,
          initState: false,
          value: 'Silver',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 23,
        },
        {
          label: 'Gold',
          currentState: false,
          initState: false,
          value: 'Gold',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 24,
        },
        {
          label: 'Platinum',
          currentState: false,
          initState: false,
          value: 'Platinum',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 25,
        },
        {
          label: 'Diamond',
          currentState: false,
          initState: false,
          value: 'Diamond',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 26,
        },
      ],
    },
    {
      label: 'Location',
      subparams: [
        {
          label: 'All',
          currentState: false,
          initState: false,
          value: 'All',
          queryName: 'location',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 35,
        },
        {
          label: 'Asia',
          currentState: false,
          initState: false,
          value: 'Asia',
          queryName: 'location',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 36,
        },
        {
          label: 'North',
          currentState: false,
          initState: false,
          value: 'North',
          queryName: 'location',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 37,
        },
        {
          label: 'East',
          currentState: false,
          initState: false,
          value: 'East',
          queryName: 'location',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 38,
        },
        {
          label: 'Forest',
          currentState: false,
          initState: false,
          value: 'Forest',
          queryName: 'location',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 39,
        },
        {
          label: 'Farm',
          currentState: false,
          initState: false,
          value: 'Farm',
          queryName: 'location',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 40,
        },
      ],
    },
    {
      label: 'Allocated',
      singleSelect: true,
      subparams: [
        {
          label: 'Yes',
          currentState: false,
          initState: false,
          value: 'true',
          queryName: 'allocated',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 42,
        },
        {
          label: 'No',
          currentState: false,
          initState: false,
          value: 'false',
          queryName: 'allocated',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'plotParams',
          id: 43,
        },
      ],
    },
  ],
};

export default plotParams;
