const statusParams = {
  name: 'statusParams',
  buttonParams: [
    {
      label: 'Status',
      subparams: [
        {
          label: 'All',
          currentState: false,
          initState: false,
          value: 'ALL',
          queryName: 'offer_type',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'statusParams',
          id: 23,
        },
        {
          label: 'On Auction',
          currentState: false,
          initState: false,
          value: 'AUCTION',
          queryName: 'offer_type',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'statusParams',
          id: 24,
        },
        {
          label: 'On sale',
          currentState: false,
          initState: false,
          value: 'ASK',
          queryName: 'offer_type',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'onAuction',
          id: 25,
        },
        {
          label: 'Active listing',
          currentState: false,
          initState: false,
          value: 'ACTIVE_LISTING',
          queryName: 'offer_type',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'onAuction',
          id: 26,
        },
        {
          label: 'Not listed',
          currentState: false,
          initState: false,
          value: 'NOT_LISTED',
          queryName: 'offer_type',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'onAuction',
          id: 27,
        },
      ],
    },
  ],
};

export default statusParams;
