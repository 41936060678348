const avatarParams = {
  name: 'avatarParams',
  buttonParams: [
    {
      label: 'Rarity',
      subparams: [
        {
          label: 'Rare',
          currentState: false,
          initState: false,
          value: 'Rare',
          queryName: 'rarity',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'avatarParams',
          id: 6,
        },
      ],
    },
    {
      label: 'Collection',
      subparams: [
        {
          label: 'Greed',
          currentState: false,
          initState: false,
          value: 'Greed',
          queryName: 'collection',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'avatarParams',
          id: 7,
        },
        {
          label: 'Astronaut',
          currentState: false,
          initState: false,
          value: 'Astronaut',
          queryName: 'collection',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'avatarParams',
          id: 8,
        },
        {
          label: 'WSB',
          currentState: false,
          initState: false,
          value: 'WSB',
          queryName: 'collection',
          get queryValue() {
            return `${this.queryName}=${this.value}`;
          },
          get htmlText() {
            return `${this.label}`;
          },
          location: 'avatarParams',
          id: 9,
        },
      ],
    },
    // {
    //   label: 'Ability',
    //   subparams: [
    //     {
    //       label: 'Wings',
    //       currentState: false,
    //       initState: false,
    //       value: 'Wings',
    //       queryName: 'ability',
    //       get queryValue() {
    //         return `${this.queryName}=${this.value}`;
    //       },
    //       get htmlText() {
    //         return `${this.label}`;
    //       },
    //       location: 'avatarParams',
    //       id: 10,
    //     },
    //     {
    //       label: 'Mining boost',
    //       currentState: false,
    //       initState: false,
    //       value: 'Mining boost',
    //       queryName: 'ability',
    //       get queryValue() {
    //         return `${this.queryName}=${this.value}`;
    //       },
    //       get htmlText() {
    //         return `${this.label}`;
    //       },
    //       location: 'avatarParams',
    //       id: 11,
    //     },
    //     {
    //       label: 'Loot boost',
    //       currentState: false,
    //       initState: false,
    //       value: 'Loot boost',
    //       queryName: 'ability',
    //       get queryValue() {
    //         return `${this.queryName}=${this.value}`;
    //       },
    //       get htmlText() {
    //         return `${this.label}`;
    //       },
    //       location: 'avatarParams',
    //       id: 12,
    //     },
    //     {
    //       label: 'Stamina boost',
    //       currentState: false,
    //       initState: false,
    //       value: 'Stamina boost',
    //       queryName: 'ability',
    //       get queryValue() {
    //         return `${this.queryName}=${this.value}`;
    //       },
    //       get htmlText() {
    //         return `${this.label}`;
    //       },
    //       location: 'avatarParams',
    //       id: 13,
    //     },
    //   ],
    // },
  ],
};

export default avatarParams;
