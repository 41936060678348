var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'items-list': true,
    'items-list--full--width': _vm.fullWidth,
  }},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isLoading)?_c('ul',{staticClass:"items-list__loading-list"},_vm._l((15),function(i){return _c('li',{key:i,staticClass:"items-list__loading-item"},[_c('div',{staticClass:"items-list__loading-item-inner"},[_c('div',{staticClass:"items-list__loading-item-card loading"})])])}),0):_c('div',[_c('ul',{staticClass:"items-list__list"},[_vm._l((_vm.marketplaceItemsList),function(card){return _c('li',{key:card.id,staticClass:"items-list__item"},[_vm._t("card",function(){return [_c('CardWrapper',{attrs:{"item":card,"cardOptions":_vm.cardOptions}})]},{"item":card,"cardOptions":_vm.cardOptions})],2)}),_vm._l((_vm.additionLoadingCardsAmount),function(i){return _c('li',{key:i,staticClass:"items-list__loading-item"},[_c('div',{staticClass:"items-list__loading-item-inner"},[_c('div',{staticClass:"items-list__loading-item-card loading"})])])})],2),(!_vm.marketplaceItemsList.length && !_vm.isLoading)?_c('div',{staticClass:"items-list__empty-wrp"},[_vm._t("empty",function(){return [_c('div',{staticClass:"items-list__empty"},[_c('h1',{staticClass:"items-list__empty-title"},[_vm._v(" No NFTs match your filtering "),_c('br'),_vm._v(" criteria. Reset filter.👇 ")]),_c('button',{staticClass:"items-list__reset-btn",on:{"click":function($event){return _vm.unselectAllSubparams()}}},[_vm._v(" Reset filter ")])])]})],2):_vm._e()])]),(
      !_vm.isLastPage
      && !_vm.isLoading
      && _vm.marketplaceItemsList.length
      && !_vm.additionLoading
    )?_c('button',{staticClass:"items-list__load-more-btn",on:{"click":function($event){return _vm.loadMore()}}},[_vm._v(" Load more ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }